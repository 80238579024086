<template>
    <perfil-form acao="INSERIR" @salvar="inserir($event)" :cancelar="cancelar" :erros="erros"></perfil-form>
</template>

<script>
import PerfisServices from './services';
import PerfilForm from './PerfilForm';

export default {
    components: {
        PerfilForm,
    },

    data() {
        return {
            erros: [],
        };
    },

    methods: {
        cancelar() {
            this.$router.push({ name: 'Perfis' });
        },

        inserir(perfilInserido) {
            this.$store.dispatch('addRequest');
            let perfilInseridoDto = {
                codigo: perfilInserido.codigo,
                descricao: perfilInserido.descricao,
            };
            if (this.$temAcessoView('CTRLACS-PA-05')) {
                perfilInseridoDto.nivel = perfilInserido.nivel;
            }
            PerfisServices.inserir(perfilInseridoDto).then(response => {
                if (response && response.success) {
                    this.$toast.add({ severity: 'success', summary: 'Perfil de Acesso', detail: 'Perfil de acesso inserido com sucesso', life: 3000 });
                    this.toDetalhar(response.data);
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toDetalhar(perfilAcessoId) {
            this.$store.dispatch('setAtualizar', true);
            this.$router.push({
                name: 'Perfis_Detalhar',
                params: {
                    id: perfilAcessoId,
                },
            });
        },
    },
};
</script>
